@keyframes save_filter_popup_animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.form {
  max-width: calc(100vw - 50px);
}

.input {
  padding: 9px 10px;
}

.field-empty {
  line-height: 0;
}

.field + .field {
  margin-top: 12px;

  @media (width < 640px) {
    margin-top: 16px;
  }
}

.field_notification {
  position: relative;
}

.field_loader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
}

.checkbox {
  margin-top: 12px;
  display: block;
}

.button {
  position: relative;
  margin: 20px -1px 0;
  padding: 9px 0 11px;
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  border-radius: 22px;
  transition: opacity 0.3s ease 0.3s;
}

.button::after {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  display: block;
  width: 16px;
  height: 16px;
  cursor: pointer;
  content: '';
  background: url('../../preloader-white.svg') no-repeat;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.button-text {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.button--loader .button-text {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.button--loader::after {
  opacity: 1;
  transition: opacity 0.3s ease 0.3s;
  animation: save_filter_popup_animation 1000ms linear infinite;
}

.info {
  margin-top: 8px;
  font-family: Lato, Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #7a7a7a;
}

.tooltip {
  font-size: 14px;
  line-height: 1.43;
}
