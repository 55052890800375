.container {
  composes: cb-reset from '../common/reset.css';
}

.container {
  cursor: pointer;
  color: var(--cb-color-main);
  transition: 0.15s color;
}

.container:hover {
  color: var(--cb-color-main-hover);
}

.active,
.active:hover {
  cursor: default;
  color: var(--cb-color-black);
}

.medium {
  composes: cb-medium_text from '../common/text_sizes.css';
}

.large {
  composes: cb-large_text from '../common/text_sizes.css';
}
