.container {
  position: absolute;
  z-index: 1;
  right: 9px;
  bottom: -2px;
  margin-bottom: -8px;
  width: 220px;
  white-space: normal;
  border-radius: 2px;
  opacity: 0.9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transform: translateY(100%);

  &::after {
    position: absolute;
    top: 4px;
    right: 12px;
    width: 14px;
    height: 14px;
    content: '';
    background-color: #182930;
    border-radius: 1px;
    transform: translateY(-50%) rotate(45deg);
  }

  &.container--left::after {
    right: initial;
    left: 12px;
  }

  &.container--left {
    right: initial;
    left: 9px;
  }

  &.container--center {
    right: 50%;
    transform: translate(50%, 100%);
  }

  &.container--center::after {
    right: 50%;
    transform: translate(50%, -50%) rotate(45deg);
  }
}

.close {
  position: absolute;
  top: 5px;
  right: 8px;
  height: 8px;
  cursor: pointer;
  color: white;
  transition: color 0.3s ease;

  &:hover {
    color: #c7c8ca;
  }
}

.popup {
  position: relative;
  z-index: 1;
  padding: 10px 24px 8px 12px;
  box-sizing: border-box;
  font-family: Lato, Arial, sans-serif;
  font-size: 13px;
  line-height: 18px;
  color: white;
  background-color: #182930;
  border-radius: 2px;
}
