.container {
  display: flex;
  align-items: baseline;
}

.square {
  width: 100%;
}

.sign {
  margin-left: 10px;
  width: 25px;
  white-space: nowrap;
}

.label:not(:last-child) {
  margin-bottom: 16px;
}
