.menu {
  composes: cb-reset from '../../../lib/base-css/base.css';
  display: block;
}

.item {
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 50px;
  text-decoration: none;
  color: var(--cb-color-gray-4);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.1);
}

button.item {
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  outline: none;
  box-shadow: none;
  background: none;
  font-weight: bold;
  font-family: Lato;
}

.itemIcon {
  margin-right: 15px;
  display: inline-flex;
  align-items: center;
}

.button {
  width: 90%;
}

.divider {
  margin: 20px 0;
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
