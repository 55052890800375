.searchPopupValue {
  padding-top: 12px;
  padding-bottom: 12px;
  min-width: initial !important; /* stylelint-disable-line */
  box-shadow: 0 1px 0 0 var(--cb-color-gray-3);

  &:first-of-type {
    padding-top: 1px;
  }

  &:last-of-type {
    padding-bottom: 3px;
    box-shadow: none;
  }
}

.searchPopup {
  right: 0;
  left: 0;
}

.searchField {
  padding-top: 0;
  /* визуальная компенсация больших букв */
  padding-bottom: 2px; /* height * (1/20) */
  /* Задаём высоту через height для IE11 (для остальных достаточно line-height) */
  height: 40px;
  font-size: 16px;
  line-height: 36px; /* height * (19/20) */
  text-overflow: ellipsis;
}

.searchFieldWithIcon {
  composes: searchField;
  padding-right: 30px;
}

.searchFieldContainerWithIcon {
  &::after {
    position: absolute;
    top: 12px;
    right: 12px;
    display: inline-block;
    width: 16px;
    height: 16px;
    content: '';
    color: var(--cb-color-gray-2);
    background: url('../../../ui/icon/icons/search-input.svg');
    transition: background 300ms ease;
  }
}

.searchFieldContainer {
  width: 100%;

  &:hover::after {
    background: url('../../../ui/icon/icons/search-input-hover.svg');
  }

  & button {
    margin-right: 5px;
    transition: background 300ms ease;

    &:hover {
      background-image: url('../../../ui/icon/icons/ic-close-circle.svg');
    }
  }
}

.async_search_field {
  position: relative;
  flex: 1 0 100%;
}
