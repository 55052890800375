.buttonStub {
  height: 50px;
}

.buttonContainer {
  width: 100%;
}

.button {
  composes: buttonStub;
  display: inline-flex;
  width: 100%;
  font-size: 16px;
  text-align: center;
  border-radius: 42px;
  align-items: center;
  justify-content: center;

  &.disabled {
    pointer-events: none;
  }
}

.preloader {
  height: 22px;

  & svg {
    height: 100%;

    & path {
      stroke: var(--cb-color-white);
    }
  }
}

.fixed {
  position: fixed;
  z-index: 10;
  right: 10px;
  left: 10px;
  bottom: 20px;
  width: auto;
  transform: translate3d(0, 0, 0);
  will-change: transform;

  @media (width >= 640px) {
    padding-right: 60px;
    padding-left: 60px;
    text-align: center;
  }
}

.fixed .button {
  max-width: 1280px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  transform: translate3d(0, 0, 0); /* Фикс задержки фиксирования кнопки на ios */
}

.buttonHint {
  margin-bottom: 115px;
}

.fixed.buttonHint {
  bottom: 115px;
  margin-bottom: 0;
}
