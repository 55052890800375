.selectMobile {
  composes: cb-reset from '../../../lib/base-css/base.css';
  position: relative;
  display: block;
}

.select {
  composes: cb-reset from '../../../lib/base-css/base.css';
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  -webkit-tap-highlight-color: transparent;

  &.native {
    position: relative;
    padding: 10px 15px;
    display: block;
    overflow: auto;
    opacity: 1;
  }

  &.native[multiple] {
    padding: 0;
  }

  &:not(.native)::-ms-expand {
    display: none;
  }

  & .optgroup,
  & .option {
    padding: 10px 15px;
  }

  & option:focus,
  & option:active,
  & option:checked {
    color: var(--cb-color-black);
    background: linear-gradient(var(--cb-color-main), var(--cb-color-main));
  }
}

.label {
  position: relative;
  padding: 9px 40px 10px 15px;
  display: block;
  overflow: hidden;
  font-size: 16px;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: var(--cb-color-black);
  border: solid 1px var(--cb-color-gray);
  border-radius: 40px;
  text-size-adjust: none;
  transition: border 300ms ease;

  &.native {
    padding: 0;
    overflow: auto;
    border-radius: 3px;
  }

  &:not(.native) {
    &::after {
      position: absolute;
      top: 0;
      right: 11px;
      bottom: 0;
      margin: auto;
      width: 20px;
      height: 20px;
      content: '';
      background-image: url('./arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    &:hover::after {
      background-image: url('./arrow-hover.svg');
    }
  }

  &:hover {
    border-color: var(--cb-color-black);
  }

  &.desktop {
    padding: 0 40px 0 15px;
    height: 28px;
    font-size: 14px;
    line-height: 24px;
  }
}
