.headerFixed {
  position: fixed;
  z-index: 16; /* over advanced filter popup (tablet version) */
  top: 0;
  padding-top: 0;
  width: 100%;
  transition: padding 0.3s, top 0.2s ease-in-out;

  &.withBanner {
    padding-top: 84px;
  }
}

.headerStub {
  z-index: -1;
  visibility: hidden;
  overflow: hidden;
  pointer-events: none;
  transition: height 0.3s;
}

.header {
  z-index: 1; /* over gk_list */
  display: flex;
  height: 50px;
  background-color: var(--cb-color-white);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  align-items: center;
}

.headers_container {
  background-color: #fff;
}

.sandwichArea {
  composes: cb-reset from '../../../lib/base-css/base.css';
  position: relative;
  width: 60px;
  height: 100%;
  -webkit-tap-highlight-color: transparent;

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.icon-area {
  margin: auto 20px;
  width: 20px;
  height: 20px;
  line-height: 1;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  & + & {
    margin-left: 0;
  }

  &:hover {
    & .counterInner > div {
      background-color: var(--cb-color-orange-hover);
    }
  }
}

.icon {
  position: relative;
  transition: opacity 100ms ease;
}

.icon-container {
  display: flex;
  flex-flow: row nowrap;
  margin-left: auto;
}

.logoArea {
  display: flex;
  width: 80px;
  height: 100%;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}

.counter {
  position: absolute;
  top: -8px;
  right: 0;
  display: flex;
  transform: translateX(50%);
}

.counterInner {
  margin-right: auto;
  margin-left: auto;
}

.leftGroup {
  display: flex;
  align-items: center;
}

.rightGroup {
  display: flex;
  height: 100%;
}

.headerItem {
  position: relative;
  display: none;
  align-items: center;

  & + & {
    margin-left: 20px;
  }
}

.headerLink {
  font-weight: var(--cb-font-semibold);
  text-decoration: none;
  cursor: pointer;
  transition: color 300ms ease;

  &:hover {
     color: var(--cb-color-main-hover);

  & .itemIcon {
      color: var(--cb-color-main-hover);
    }
  }
}

.link {
  /* composes: cb-reset from '../../../lib/base-css/base.css'; */
  cursor: pointer;
  color: var(--cb-color-main);
}

.itemIcon {
  margin-right: 6px;
  display: inline-flex;
  align-items: center;
  transition: color 300ms ease;
}

.lkLabel {
  height: 14px;
  font-size: 10px;
  font-weight: var(--cb-font-semibold);
  color: #9a9a9a;
}

.userName {
  overflow: hidden;
  max-width: 110px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (width >= 640px) {
  .header {
    justify-content: inherit;
  }

  .rightGroup {
    margin-left: auto;
  }

  .headerItem {
    display: flex;
  }
}
