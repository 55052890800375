.container {
  display: flex;
  overflow-y: hidden;
  flex-direction: column;
  height: 100%;
  background-color: var(--white_100);
}

.header,
.body,
.footer {
  background-color: inherit;
}

.header {
  border-bottom: 1px solid var(--gray10_100);
}

.heading {
  display: grid;
  grid-template-columns: 1fr 48px;
  align-items: center;
}

.title-wrapper {
  padding: 16px 0 16px 16px;
}

.content {
  padding: 0 16px;
  overflow-y: auto;
  flex-basis: 100%;
}

.nested-checkbox-groups-wrapper {
  padding-bottom: 28px;
  display: grid;
  grid-row-gap: 28px;

  &.multi-groups {
    padding-top: 28px;
  }
}

.search-field-wrapper {
  margin: 16px;
}

.footer {
  padding: 12px 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 8px;
  border-top: 1px solid var(--gray10_100);
}

.close-button {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: transparent;
  border: none;
  align-items: center;
  justify-content: center;
}
