.sidebarContent {
  composes: cb-reset from '../../../lib/base-css/base.css';
  z-index: 150;
  display: flex;
  overflow-y: hidden;
  flex-direction: column;
  min-width: 260px;
  background-color: #182930;
  justify-content: flex-start;
  flex: 1 1 auto;
}

.count-container {
  margin-left: 8px;
  padding: 4px 8px;
  display: inline-block;
  box-sizing: border-box;
  min-width: 28px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100px;
}

.counter {
  display: inline-block;
  transform: translateY(2px);
}

.toggleButtonContainer {
  flex: 0 0 auto;
}

.menuContainer {
  padding-bottom: 30px;
  overflow-y: auto;
  flex: 1 1 100%;
}

.region_special {
  padding-top: 14px;
  padding-bottom: 14px;
  display: inline-block;
  text-align: left;
  line-height: 22px;
  border: 0;
  outline: none;
  background: none;
}

.region_special .icon {
  margin-bottom: 0;
}

.downloadAppButton {
  margin: 20px 20px 0;
  min-width: 220px;
  align-self: center;
}

.icon {
  position: relative;
  margin-bottom: -3px;
  margin-left: 10px;
  display: inline-block;
  width: 19px;
  height: 17px;
  border-radius: 3px;

  & svg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }

  &.new {
    position: relative;
    top: -2px;
    width: 28px;
    height: 14px;
  }

  &.pik {
    top: 4px;
    padding: 1px;
    width: 20px;
    height: 20px;
    background-color: var(--cb-color-white);
  }

  &.lsr {
    width: 57px;
    height: 18px;

    & svg {
      width: 100%;
    }
  }
}

.lk {
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
}

.lkLink {
  padding-bottom: 2px;
  align-items: flex-end;
}

.loginLink {
  line-height: 33px;
}

.userName {
  overflow: hidden;
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.logoutLink {
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  outline: none;
  box-shadow: none;
  line-height: 35px;
  background: none;
}

.region_special-endline {
  white-space: nowrap;
}

.new {
  position: relative;
  top: 1px;
  margin-bottom: -3px;
  margin-left: 10px;
  display: inline-block;
  width: 28px;
  height: 14px;
  border-radius: 3px;
}
