.margins {
  &:not(:last-child) {
    margin-bottom: 25px;
  }

  @nest :global(.tabletViewAllowed) & {
    @media (width >= 640px) {
      margin-top: 10px;
      margin-bottom: 10px;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  @nest :global(.desktopViewAllowed) & {
    margin-top: 5px;
    margin-bottom: 5px;

    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}

.field {
  composes: margins;
  vertical-align: top;

  @nest :global(.tabletViewAllowed) & {
    @media (width >= 640px) {
      display: inline-block;
    }
  }

  &.withFurnitureContainer {
    margin-bottom: 10px;
  }
}

.fieldBlock {
  padding: 15px 15px 25px;

  @nest :global(.tabletViewAllowed) & {
    @media (width >= 640px) {
      padding-top: 5px;
      padding-bottom: 15px;

      /* stylelint-disable-next-line selector-max-compound-selectors, selector-max-specificity */
      & + .fieldBlock {
        padding-top: 14px;
      }
    }
  }

  &:empty {
    display: none;
  }
}

.fieldBlock:empty {
  /* не получится использовать 'display: none
  в iOS в safari из-за 'display: none' происходит пропадание блоков
  см. https://cianru.atlassian.net/browse/CSSSR-6589 */
  margin: 0;
  padding: 0;
  visibility: hidden;
  height: 0;
  border: none;
}

.title {
  composes: cb-reset from '../../../lib/base-css/base.css';
  margin-bottom: 25px;
  display: block;
  font-size: 16px;
  font-weight: var(--cb-font-semibold);
  color: var(--cb-color-black);

  @nest :global(.tabletViewAllowed) & {
    @media (width >= 640px) {
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }
}

.label {
  composes: field;
}

.fakeLabel {
  vertical-align: bottom;
}

.checkbox {
  composes: margins;
}

.labelCheckbox {
  display: inline-block;
  padding-bottom: 6px;
  font-size: 16px;
  cursor: pointer;

  &::before,
  &::after {
    transition: background 300ms ease, border 300ms ease;
  }

  &:hover {
    &::before {
      border-color: var(--cb-color-gray-1);
    }
  }
}

.labelCheckbox.checkbox--with_custom {
  white-space: normal;
  vertical-align: top;

  &::before {
    margin-top: -8px;
  }

  &::after {
    margin-top: -5px;
  }
}

.containerLabel {
  margin-bottom: 5px;
}

.labelCheckbox.checked {
  &:hover {
    &::before {
      background-color: var(--cb-color-main-hover) !important; /* stylelint-disable-line declaration-no-important */
      border-color: var(--cb-color-main-hover) !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.inputContainer {
  & input {
    &:hover {
      z-index: 1;
      border-color: var(--cb-color-black);
    }
  }
}

.buttonCheckbox {
  cursor: pointer;

  & > label > div {
    transition: background 300ms ease, border 300ms ease;
  }

  &:hover {
    & > label > div {
      border-color: var(--cb-color-black);
    }

    & input:checked + div {
      background-color: var(--cb-color-main-hover);
      border-color: var(--cb-color-main-hover);
    }
  }
}

.buttonRadio {
  &:hover {
    z-index: 1 !important; /* stylelint-disable-line declaration-no-important */

    &::before,
    &::after {
      border-color: var(--cb-color-black);
    }
  }

  &.active {
    &:hover {
      background-color: var(--cb-color-main-hover);
      border-color: var(--cb-color-main-hover);

      &::before,
      &::after {
        border-color: var(--cb-color-main-hover);
      }
    }
  }
}

.apartmentButton,
.bathroomButton {
  composes: buttonRadio;
  overflow: hidden;
  text-overflow: ellipsis;
}

.priceTypeButton {
  composes: bathroomButton;
}

.houseStatusButton {
  composes: buttonRadio;
  padding: 0 11px;
}

.decorationField {
  margin-right: 60px;
}

.block-reset {
  border: none;
  box-shadow: none;
}

.filter-layout {
  padding: 15px 15px 25px;
  border-top: 1px solid #d8d8d8;
}

.filter-layout:empty {
  display: none;
}

.filter-title,
.filter-label {
  margin-bottom: 20px;
  display: block;
  font-size: 14px;
  color: #7a7a7a;
}

.filter-label {
  margin-bottom: 6px;
}

.photo-filter {
  margin-bottom: 15px;
}

.filter-publication {
  margin-bottom: 15px;
}

.coworking-price-type-radio-buttons-container {
  margin-bottom: 20px;
}

