.wrapper {
  overflow: auto;
}

.container {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  width: calc(100% + 28px);
}

.button {
  margin: 0 5px 0 0;
  padding: 3px 16px 5px;
  font-size: 14px;
  line-height: 20px;
  color: #7a7a7a;
  border-radius: 42px;
  background: #f4f4f4;
  box-shadow: none;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.button:last-child {
  margin: 0;
}

.button:active {
  transform: scale(0.98);
}

.button-active {
  background: rgba(43, 135, 219, 0.1);
  color: #2b87db;
}
