.timeInputContainer {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.timeInput {
  margin: 0 10px;
  width: 145px;
}
