.buttons {
  margin-bottom: -10px;

  & > div {
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;

    & div {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}
