.item {
  display: flex;
  justify-content: space-between;
  flex: 1 0;
  padding: 10px;
}

.item:hover {
  cursor: pointer;
}

.item .content {
  display: flex;
}

.item .part {
  margin: 0 0 0 12px;
}

.item .label {
  padding-left: 14px;
}
