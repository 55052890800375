.root > h3 {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  padding-top: 20px;
}

.amenities-block {
  padding: 0 15px;
}

.checkbox-container {
  display: flex;
  border-bottom: 1px solid #e8e9ec;

  &.checked {
    .checkbox-label::before {
      background-color: #0468ff;
      border-color: #0468ff;
    }
  }
}

.checkbox-label {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  padding: 11px 0 12px;
  width: 100%;

  &::before {
    top: calc(50% - 4px);
    right: 1px;
    width: 20px;
    height: 20px;
    border-radius: 2px;
  }

  &::after {
    right: 6px;
  }

  &::before,
  &::after {
    left: unset;
  }
}

.collapsed-button {
  margin: 16px 0 24px;
  font-family: Lato, sans-serif;
}

.collapsed-block {
  display: none;
}
