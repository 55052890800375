.container {
  height: 40px;
}

.button {
  padding-right: 20px;
  padding-left: 20px;
  font-size: 16px;
  white-space: nowrap;
}
