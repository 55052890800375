.page {
  composes: cb-reset from '../../../lib/base-css/base.css';
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.pageGray {
  background-color: var(--cb-color-gray-4);
}

.pageLight {
  background-color: var(--cb-color-white);
}

.pageContent {
  padding-top: 0;
  display: flex;
  flex-direction: column;
  transition: padding 0.3s;
  backface-visibility: hidden;
  flex: 1 0 auto;
}
