.worplace-container {
  margin-top: 24px;
}

.coworking-workplaces {
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;

  &.disabled div {
    color: #fff !important;
    background-color: #2b87db !important;
    border-color: #2b87db !important;
  }
}

.label {
  margin-top: 8px;
}
