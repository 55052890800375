.footer {
  composes: cb-reset from '../../../lib/base-css/base.css';
  margin-right: 10px;
  margin-left: 10px;
  padding: 20px 0 30px;
  text-align: center;
  background-color: var(--cb-color-gray-4);
  border-top: 1px solid var(--cb-color-gray-5);
}

.fullVersion {
  composes: cb-reset from '../../../lib/base-css/base.css';
  margin-top: 20px;
  text-decoration: underline;
  cursor: pointer;
  color: var(--cb-color-gray-1);
  transition: color 300ms ease;

  &:hover {
    color: var(--cb-color-black);
  }
}

.downloadAppButton {
  display: flex;
  justify-content: center;
}
