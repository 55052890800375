:root {
  --cb-color-black: #121212;
  --cb-color-gray-1: #7A7A7A;
  --cb-color-gray-2: #C9C9C9;
  --cb-color-gray-3: #E4E4E4;
  --cb-color-gray-4: #F4F4F4;
  --cb-color-gray-5: #D8D8D8;
  --cb-color-white: #FFFFFF;

  --cb-color-main: #2B87DB;
  --cb-color-main-hover: #256BA5;

  --cb-color-gray: #C9C9C9;
  --cb-color-gray-hover: #7A7A7A;

  --cb-color-orange: #FF9D00;
  --cb-color-orange-hover: #AF6800;

  --cb-color-red: #FF5050;
  --cb-color-red-hover: #B23939;

  --cb-color-green: #2E9E00;
  --cb-color-green-hover: #185100;

  --cb-color-sand: #FFF5D4;
  --cb-color-sand-hover: #FFEFBD;

  --cb-color-popup_overlay: rgba(24, 41, 48, .8);

  --cb-font-light: 300;
  --cb-font-regular: 400;
  --cb-font-semibold: 600;
  --cb-font-bold: 700;
  --cb-font-black: 900;
}

.cb-reset, .cb-reset:before, .cb-reset:after {
  all: initial;
  box-sizing: border-box;
  color: #121212;
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%; /* чтобы шрифт не увеличивался при повороте экрана на iOS */
  -moz-osx-font-smoothing: grayscale;
}

.cb-small_text {
  font-size: 14px;
  line-height: 20px;
}

.cb-medium_text {
  font-size: 20px;
  line-height: 28px;
}

.cb-large_text {
  font-size: 26px;
  line-height: 36px;
}

.cb-visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
