@import '@cian/ui-kit/colors/variables.css';

.search {
  position: relative;
  z-index: 0;
  display: flex;
  width: 100%;
  height: 50px;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  pointer-events: all;
  color: var(--cb-color-main);
  background-color: var(--cb-color-white);
  box-shadow: 0 1px 0 0 #e6e6e6, inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  flex-flow: row nowrap;
}

.content {
  composes: cb-reset from '../../../lib/base-css/base.css';
  position: relative;
  padding: 0 3px;
  display: flex;
  line-height: 17px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  color: var(--primary_100);
  transition: color 300ms ease;
  flex: 1 0 33%;

  &::after {
    position: absolute;
    top: 7px;
    left: 0;
    display: none;
    width: 1px;
    height: 36px;
    content: '';
    background-color: #e4e4e4;
  }

  & + &::after {
    display: block;
  }

  @media (max-width: 350px) {
    flex: 1 1 auto;
  }
}

.button-content {
  position: relative;
  margin: auto;
  display: inline-block;
}

.save-icon {
  margin-right: 6px;
  display: inline-block;
  transform: translateY(2px);
}

.iconSearch,
.iconMap,
.iconList {
  padding-right: 6px;
  display: inline-block;
}

.iconSearch,
.iconMap {
  transform: translateY(3px);
}

.popupContainer {
  /* Ниже кнопки поиска */
  /* stylelint-disable-next-line declaration-no-important */
  z-index: 9 !important;
  padding: 5px;
}

.popupContext {
  padding-top: 50px;
  background-color: var(--cb-color-gray-4);
}

.fixPerformance {
  transform: translate3d(0, 0, 0);
  will-change: transform;
}

.noScroll {
  overflow: hidden;
}

.advancedCount {
  margin-left: 5px;
  display: inline-flex;
  font-size: 10px;
  align-content: center;
  justify-content: center;
}

div.count {
  margin-left: 5px;
}

div.count_with_one_symbol {
  margin-left: 5px;
  padding-right: 0;
  padding-left: 0;
}

.heartbeat {
  animation: heartbeat 1s linear 5;
}

@keyframes heartbeat {
  0%,
  100% {
    transform: scale(0.95);
  }

  20% {
    transform: scale(1.1);
  }
}
