.sandwich {
  composes: cb-reset from '../../../lib/base-css/base.css';
  position: relative;
  width: 60px;
  height: 50px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
