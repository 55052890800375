:root {
  --arrowSize: 6px;
  --arrowOffset: 12px;
  --tooltipOffset: 6px;
}

.tooltip {
  composes: cb-reset from '../../../lib/base-css/base.css';
  position: absolute;
  padding: 12px;
  background: var(--cb-color-white);
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

  &::after {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    content: '';
    border: var(--arrowSize) solid transparent;
  }
}

.alignLeft {
  right: calc(100% + var(--arrowSize) + var(--tooltipOffset));
  bottom: calc(var(--arrowSize) - var(--arrowOffset));

  &::after {
    left: 100%;
    bottom: var(--arrowOffset);
    border-left-color: var(--cb-color-white);
  }
}

.alignRight {
  left: calc(100% + var(--arrowSize) + var(--tooltipOffset));
  bottom: calc(50% - var(--arrowSize) - var(--arrowOffset));

  &::after {
    left: calc(1px - var(--arrowSize) * 2);
    bottom: var(--arrowOffset);
    border-right-color: var(--cb-color-white);
  }
}

.alignTop {
  left: 50%;
  bottom: calc(100% + var(--arrowSize) + var(--tooltipOffset));

  &::after {
    left: 50%;
    bottom: calc(1px - var(--arrowSize) * 2);
    border-top-color: var(--cb-color-white);
  }
}

.alignBottom {
  left: 50%;
  top: calc(100% + var(--arrowSize) + var(--tooltipOffset));

  &::after {
    left: 50%;
    top: calc(1px - var(--arrowSize) * 2);
    border-bottom-color: var(--cb-color-white);
  }
}

.tooltipAnimationContainer {
  transition: opacity 300ms, transform 300ms;
}

.tooltipAnimationContainerFromRight {
  transform: translateX(10px);
}

.tooltipAnimationContainerFromBottom {
  transform: translateY(10px);
}

.showAnimation {
  transform: translate(0, 0);
}
