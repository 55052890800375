.container {
  font-weight: var(--cb-font-semibold);
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
}

.containerNowrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.separator {
  composes: cb-reset from '../../../lib/base-css/base.css';
  margin-right: 7px;
  margin-left: 7px;
  color: var(--cb-color-gray-1);
}

.feature {
  display: inline-block;
  white-space: nowrap;
}

.feature-wrapper {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
