:root {
  --cb-color-pik: #193b74;
  --cb-color-lsr: #e01d42;
}

.footer {
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  background-color: var(--cb-color-white);
  border-top: solid 1px var(--cb-color-gray-3);
}

.button {
  composes: cb-reset from '../../../lib/base-css/base.css';
  margin: 0 auto;
  padding-top: 8px;
  display: block;
  width: 290px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  border-radius: 42px;
  -webkit-tap-highlight-color: transparent;

  &.pik {
    color: var(--cb-color-pik);
    border: solid 1px var(--cb-color-pik);
  }

  &.lsr {
    color: var(--cb-color-lsr);
    border: solid 1px var(--cb-color-lsr);
  }
}

.schedule {
  composes: cb-reset from '../../../lib/base-css/base.css';
  margin-top: 15px;
  margin-bottom: 20px;
  display: block;
  font-size: 16px;
  line-height: 1.25;
  text-align: center;
  color: var(--cb-color-gray-1);
}

.link {
  composes: cb-reset from '../../../lib/base-css/base.css';
  margin: 0 auto;
  padding-top: 20px;
  display: block;
  width: 280px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  border-top: 1px solid var(--cb-color-gray-3);
  -webkit-tap-highlight-color: transparent;

  &.pik {
    color: var(--cb-color-pik);
  }

  &.lsr {
    color: var(--cb-color-lsr);
  }
}
