.container {
  text-align: center;
}

.gray {
  color: var(--cb-color-gray-1);
}

.black {
  color: var(--cb-color-black);
}
