/* След. 3 класса используются как общие стили для поиска с саджестами todo вынести */
.searchPopup {
  right: 0;
  left: 0;

  @media (width >= 640px) {
    margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.4);
  }
}

.searchPopupValue {
  padding-top: 12px;
  padding-bottom: 12px;
  min-width: initial !important; /* stylelint-disable-line */
  box-shadow: 0 1px 0 0 var(--cb-color-gray-3);

  &:first-of-type {
    padding-top: 16px;
  }

  &:last-of-type {
    padding-bottom: 18px;
    box-shadow: none;
  }

  @media (width >= 640px) {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
  }
}

.searchField {
  padding-top: 0;
  /* Чтобы текст не залезал на кнопку удаления */
  padding-right: 35px;
  /* визуальная компенсация больших букв */
  padding-bottom: 2px; /* height * (1/20) */
  /* Задаём высоту через height для IE11 (для остальных достаточно line-height) */
  height: 40px;
  font-size: 16px;
  line-height: 36px; /* height * (19/20) */
  text-overflow: ellipsis;
  background-color: transparent;
}

.searchPopupContent {
  padding-top: 0;
  padding-bottom: 0;
}

.geo-input {
  padding: 0 35px 2px 11px;
  box-sizing: border-box;
  font-family: Lato;
  font-size: 16px;
}
.suggest-two-lines div[data-group="addresses"] span[class*="relative"] {
  padding: 4px 0;
  /* stylelint-disable-next-line */
  display: -webkit-box;
  overflow: hidden;
  line-height: 20px;
  white-space: pre-line;
  text-overflow: ellipsis;
  /* stylelint-disable-next-line */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
