.sandwich {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  width: 20px;
  height: 14px;
}

.one,
.two,
.three {
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: #737a8e;
  transition-duration: 300ms;
  transition-property: transform, opacity;
}

.lightSandwich .one,
.lightSandwich .two,
.lightSandwich .three {
  background-color: var(--cb-color-white);
}

.one {
  top: 0;
}

.two {
  top: 6px;
}

.three {
  top: 12px;
}

.close {
  & .one {
    transform: translateY(6px) rotate(45deg);
  }

  & .two {
    opacity: 0;
    transform: scale(0);
  }

  & .three {
    transform: translateY(-6px) rotate(-45deg);
  }
}
