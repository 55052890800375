.field {
  composes: field from '../field.css';
  position: relative;
}

.tag {
  margin-top: 10px;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.hideButton {
  margin-right: 10px;
}
