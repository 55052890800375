.prevButton {
  composes: cb-reset from '../../../lib/base-css/base.css';
  position: relative;
  margin-top: 45px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}

.prevIcon {
  margin-top: 21px;
  margin-right: -6px;
  margin-left: 16px;
  display: flex;
  transition: color 300ms ease;
  transform: scaleX(-1);
  flex-shrink: 0;
  align-items: flex-start;
}

.title {
  margin: 15px 45px 15px 15px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  cursor: pointer;
  color: var(--cb-color-black);
  transition: color 300ms ease;
}
