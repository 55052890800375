
.header {
  padding: 0 15px 15px;
  height: auto;
  border-bottom: 1px solid var(--cb-color-gray-2);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.content {
  padding: 106px 6px 90px;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: var(--cb-color-gray-4);
  transform: translate3d(0, 0, 0);
}

.popupContainer {
  padding: 5px;
}

.popupInner {
  @media (width >= 640px) {
    max-width: 360px;
    max-height: 415px;
  }
}

.closeIcon {
  height: 50px;
}

.fixedContainer {
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 20px;
  padding: 0 5px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  transform: translate3d(0, 0, 0);
  will-change: transform;
}

.ieFixedContainer {
  bottom: 25px;
  padding: 0 10px;
}

.save_button_footer {
  left: 50%;
  width: 300px;
  transform: translate3d(-50%, 0, 0);
}

.saveButton {
  width: 100%;
  height: 50px;
  font-size: 16px;
  text-align: center;
  border-radius: 42px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  transition: background 300ms ease, border 300ms ease;

  &:hover {
    background-color: var(--cb-color-orange-hover);
    border-color: var(--cb-color-orange-hover);
  }
}

.groupTitle {
  composes: cb-reset from '../../../lib/base-css/base.css';
  margin: 17px 0 17px 14px;
  display: block;
  font-size: 16px;
  font-weight: bold;
}

.groupTitleContainer {
  overflow: overlay;
}

.firstNodesWrapper {
  margin-top: 20px;
}

.backButton {
  margin-top: 0;

  &:hover span,
  &:hover p {
    color: #13344b;
  }
}

.backButton p {
  margin-bottom: 0;
}

.backButton i {
  margin-left: 10px;
}

.noResults {
  composes: cb-reset from '../../../lib/base-css/base.css';
  margin-top: 20px;
  display: block;
  font-size: 16px;
  text-align: center;
  color: var(--cb-color-gray-1);
}

.preloader {
  composes: cb-reset from '../../../lib/base-css/base.css';
  margin-top: 40px;
}

.searchFieldNavigateContainer {
  display: flex;
  width: 100%;
}

.searchFieldNavigateContainer > div:first-child {
  display: flex;
  flex-grow: 1;
}

.navigateIcon {
  padding-left: 12px;
  display: flex;
  align-items: center;
}

.popupContainer .header {
  flex-wrap: wrap;

  .closeIcon {
    padding-right: 5px;
  }
}
