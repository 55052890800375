.field {
  composes: field from '../field.css';
  display: flex;
  font-size: 16px;
}

.buttons {
  height: 100%;
}

.linkButton {
  composes: cb-reset from '../../../../lib/base-css/base.css';
  height: 100%;
  font-size: 16px;
  font-weight: inherit;
  cursor: pointer;
  user-select: none;
  color: var(--cb-color-main);
  transition: color 300ms ease;

  &:hover {
    color: var(--cb-color-main-hover);
  }

  &:not(:first-child) {
    margin-left: 10px;
  }

  &::-ms-expand {
    display: none;
  }

  &:focus::-ms-value {
    color: var(--cb-color-main);
    background-color: transparent;
  }
}

.popupContainer {
  padding: 5px;
}

.popupInner {
  @media (width >= 640px) {
    max-width: 360px;
    max-height: 415px;
  }
}

.popupContent {
  margin-top: 104px;
  padding-top: 10px;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
}

.withoutTabs {
  margin-top: 49px;
  padding-top: 10px;
}

.contentPadding {
  padding: 0 15px 70px;
}

.closeButton {
  & span {
    position: absolute;
    top: 15px;
    color: rgba(0, 0, 0, 0.56);
    transition: color 300s ease;

    &:hover {
      & svg {
        color: var(--cb-color-black);
      }
    }
  }
}

.popupHeader {
  height: 105px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.tabsContainer {
  margin: 0 6px;
  height: 40px;
}

.tabButton {
  composes: buttonRadio from '../field.css';
  font-size: 16px;
}

.checkbox {
  margin-bottom: 24px;
  display: block;

  & label {
    font-size: 16px;
  }
}

.filterCheckbox {
  composes: checkbox;
  margin: 26px 0;
}

.filters {
  margin-bottom: 24px;
  padding: 0 15px;
}

.groupTitle {
  margin-top: 28px;
  margin-bottom: 18px;
  font-size: 16px;
  font-weight: bold;
}

.checkboxButton {
  margin-bottom: 15px;
  display: block;
  cursor: pointer;

  & label > div {
    padding: 4px 15px 6px;
    width: initial;
    height: 30px;
    font-size: 14px;
    font-weight: bold;
    background-color: var(--cb-color-gray-4);
    transition: background 300ms ease, color 300ms ease;
  }

  &:hover {
    & > label > div {
      border-color: var(--cb-color-black);
    }
  }
}

.divider {
  margin-top: 24px;
  height: 1px;
  background-color: var(--cb-color-gray-5);
}

.noResults {
  margin-top: 41px;
  font-size: 16px;
  text-align: center;
  color: var(--cb-color-gray-1);
}

.counter {
  position: absolute;
  top: -7px;
  right: 4px;
}

.saveButton {
  width: 100%;
  height: 50px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  border-radius: 42px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  transition: background 300ms ease, border 300ms ease;

  &:hover {
    background-color: var(--cb-color-orange-hover);
    border-color: var(--cb-color-orange-hover);
  }
}

.fixedContainer {
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 20px;
  padding: 0 5px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  transform: translate3d(0, 0, 0);
  will-change: transform;
}

.saved_button {
  max-width: 300px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.ieFixedContainer {
  bottom: 25px;
  padding: 0 10px;
}

.preloader {
  margin-top: 4px;
}

.specifyText {
  margin-right: 10px;
}

.text-grayed {
  color: #7a7a7a;
}
