.range {
  display: inline-flex;
  align-items: center;

  @media (width >= 640px) {
    min-width: 270px;
  }
}

.container {
  flex-grow: 1;

  & input {
    background: transparent;
  }

  @media (width < 640px) {
    display: flex;
    width: 100%;
  }
}

.adaptiveContainer {
  @media (width < 640px) {
    display: flex;
    width: 100%;
  }
}

/* После обновления @cian/components перенести это в RangeInputGroup */
.container span:first-child {
  display: flex;
  align-self: center;
}

.sign {
  margin-left: 10px;
  display: block;
  min-width: 27px;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
}

.label {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.tabletNoFlexRangeGroup {
  @media (width >= 640px) {
    width: 240px;
    flex: 0 0 240px;
  }
}

.tabletFlexRangeGroup {
  align-items: center;
  min-width: 140px;

  & input {
    text-overflow: ellipsis;
    border: none;
  }

  & span:last-child {
    position: relative;
  }

  & span:last-child::before {
    content: '';
    position: absolute;
    left: 0;
    top: 12px;
    bottom: 12px;
    border-left: 1px solid var(--cb-color-gray-3);
  }

  & span input {
    padding-left: 16px;
  }
}
