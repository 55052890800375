.searchFieldContainerWithIcon {
  &::after {
    position: absolute;
    top: 12px;
    right: 12px;
    display: inline-block;
    width: 16px;
    height: 16px;
    content: '';
    color: var(--cb-color-gray-2);
    background: url('../../../../ui/icon/icons/search-input.svg');
  }
}

.geoTag {
  margin-right: 5px;
  padding-right: 27px;
  overflow: hidden;
  min-width: 90px;
  max-width: 100%;
  line-height: 1.3;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 0;
  cursor: pointer;

  &:last-child {
    flex-shrink: 1;
  }
}

.loader {
  composes: cb-reset from '../../../../../lib/base-css/base.css';
  position: relative;
  margin-top: 10px;
  display: inline-flex;
  white-space: nowrap;
  transform: translateY(4px);
  align-items: center;

  &Icon {
    margin-right: 8px;
    width: 16px;
    min-width: 16px; /* fixes wrong width in small containers */
    height: 16px;
  }
}

.buttons {
  margin-top: 15px;
}

.moreTagsButton {
  padding-top: initial;
  padding-bottom: initial;
  height: 30px;
  white-space: nowrap;
  cursor: pointer;
}

.clearTagsButton {
  composes: moreTagsButton;
  cursor: pointer;
  background-color: var(--cb-color-gray-2);
  border-color: var(--cb-color-gray-2);

  &:hover {
    background-color: #979797;
    border-color: #979797 !important; /* stylelint-disable-line declaration-no-important */
  }
}

.tagsContainer {
  /* fixes bottom offset http://stackoverflow.com/questions/31149640/margin-bottom-with-overflow-hidden */
  overflow: hidden;
  width: 100%;
  line-height: 0;
  flex: 1 1 auto;

  @media (width >= 640px) {
    display: inline-flex;
    flex-wrap: wrap;
  }
}

.loaderInTags {
  margin-top: 0;
  margin-right: 5px;
  overflow: hidden;
  transform: translateY(1px);

  &Text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.release-year {
  position: absolute;
  top: 5px;
  right: 25px;
  min-width: 43px;
}

.under-construction {
  padding-right: 70px;
  min-width: 100px;
}
