.field {
  composes: field from '../field.css';
}

.rooms {
  min-width: 270px;
}

.button {
  composes: buttonCheckbox from '../field.css';

  & + .button {
    margin-left: 4px;
  }

  & > label > div {
    padding: 0 15px 0 14px;
    display: inline-flex;
    min-width: 41px;
    height: 41px;
    line-height: 19px;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    & > label > div {
      border-color: var(--cb-color-black);
    }

    & input:checked + div {
      background-color: var(--cb-color-main-hover);
      border-color: var(--cb-color-main-hover);
    }
  }
}

.rooms-count-row {
  display: flex;
}

.rooms-special-counts {
  margin-top: 8px;
  display: flex;
}

/* .rooms-count-row .button > label > div {
  width: 50px;
  height: 50px;
} */

.rooms-special-counts .button > label > div {
  width: 100%;
}

.rooms-special-counts .button > label > div:last-child {
  white-space: nowrap;
}

/* @media (max-width: 320px) {
  .rooms-count-row .button > label > div {
    width: 41px;
    height: 41px;
  }
} */
