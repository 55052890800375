.button {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 9px 35px 10px 15px;
  background-color: var(--white_100);
  transition: all 0.3s;
  cursor: pointer;
  border: solid 1px var(--cb-color-gray);
  border-radius: 40px;
  position: relative;
}

.info-block {
  display: flex;
  align-items: center;
  margin-left: 18px;
}

.count {
  padding: 0 4px;
  background-color: var(--gray6_100);
  border-radius: 4px;
}

.label-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrow-wrapper {
  position: absolute;
  right: 11px;
  width: 20px;
  height: 20px;
  background-image: url('./arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
}
