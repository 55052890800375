.tip {
  width: 254px;
  left: -33%;
  background: #182930;
  color: white;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.tip::after {
  border-bottom-color: #182930;
}
