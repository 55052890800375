.container,
.label {
  composes: cb-reset from '../common/reset.css';
}

.container {
  position: relative;

  &:hover:not(.mobile) .label::before {
    border-color: var(--cb-color-gray-hover);
  }
}

.label {
  padding-left: calc(14px + 8px);
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  text-size-adjust: none;

  &::before,
  &::after {
    position: absolute;
    display: block;
    cursor: pointer;
    content: '';
  }

  &::before {
    top: 50%;
    left: 0;
    margin-top: -7px;
    width: 14px;
    height: 14px;
    background: var(--cb-color-white);
    border: 1px solid var(--cb-color-gray);
    border-radius: 2px;
    transition: 0.15s border, 0.15s background;
    box-sizing: border-box;
  }

  &::after {
    top: 50%;
    left: 2px;
    margin-top: -5px;
    width: 10px;
    height: 8px;
    background-image: url('./checkbox-arrow.svg');
  }

  &.mobile {
    &::before {
      margin-top: -9px;
      width: 16px;
      height: 16px;
    }

    &::after {
      left: 3px;
      margin-top: -6px;
    }
  }
}

.checked {
  & .label::before,
  &:hover:not(.mobile) .label::before {
    background-color: var(--cb-color-main);
    border-color: var(--cb-color-main);
  }
}

.invalid {
  & .label::before,
  &:hover:not(.mobile) .label::before {
    border-color: var(--cb-color-red);
  }
}

.disabled {
  .label {
    cursor: default;
    color: rgba(18, 18, 18, 0.5);

    &::before,
    &::after {
      cursor: default;
    }
  }

  & .label::before,
  &:hover:not(.mobile) .label::before {
    border-color: rgba(201, 201, 201, 0.5);
  }
}

.required::before {
  position: absolute;
  top: calc(50% - 2px);
  left: -8px;
  width: 4px;
  height: 4px;
  content: '';
  background-color: var(--cb-color-red);
  border-radius: 50%;
}
