.text {
  margin: 0;
  display: block;
  line-height: 1.3;
  color: #0f0f0f;
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
}

.alignLeft {
  text-align: left;
}

.title {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.1;
  color: var(--cb-color-black);
}

.colorWhite {
  color: var(--cb-color-white);
}

.colorGray {
  color: var(--cb-color-gray-1);
}

.colorBlack {
  color: var(--cb-color-black);
}

.sizeSmall {
  composes: cb-small_text from '../../../lib/base-css/base.css';
}

.sizeMedium {
  font-size: 16px;
}

.fontWeightSemiBold {
  font-weight: var(--cb-font-semibold);
}

.fontWeightBold {
  font-weight: var(--cb-font-bold);
}

.sizeMediumTitle {
  font-size: 18px;
}

.sizeLargeTitle {
  font-size: 22px;
}

.shadow {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}
