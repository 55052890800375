.button {
  padding-right: 25px;
  padding-left: 25px;
  display: block;
  height: 42px;
  line-height: 42px;
  text-align: center;
  text-decoration: none;
  border: 1px solid var(--cb-color-gray-2);
  border-radius: 20px;
  -webkit-tap-highlight-color: transparent;
}

.buttonLight {
  composes: button;
  color: var(--cb-color-white);

  &:active {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.buttonBlack {
  composes: button;
  color: var(--cb-color-black);
  border: 1px solid var(--cb-color-black);

  &:active {
    color: var(--cb-color-black);
  }

  & path {
    fill: var(--cb-color-black);
  }
}

.buttonGray {
  composes: button;
  color: var(--cb-color-gray-1);

  &:active {
    color: var(--cb-color-black);
  }

  & path {
    fill: var(--cb-color-gray-1);
  }
}

.text {
  padding-left: 10px;
  line-height: 42px;
}

.icon {
  padding-bottom: 2px;
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
}

.appleIcon {
  composes: icon;
  height: 26px;
}

.androidIcon {
  composes: icon;
  height: 22px;
}
