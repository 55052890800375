.classes {
  display: flex;
}

.field {
  composes: field from '../field.css';
}

.checkbox {
  margin-left: 6px;
}

.checkbox:first-of-type {
  margin-left: 0;
}

