.list {
  composes: cb-reset from '../../../lib/base-css/base.css';
  display: block;
  width: 100%;
  background-color: var(--cb-color-white);
  border: 1px solid var(--cb-color-gray-5);
  border-radius: 3px;
}

.item {
  composes: cb-reset from '../../../lib/base-css/base.css';
  display: block;
  border-bottom: 1px solid var(--cb-color-gray-5);

  &:last-child {
    border-bottom: none;
  }
}

.itemButton {
  composes: cb-reset from '../../../lib/base-css/base.css';
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  color: var(--cb-color-black);
  transition: background 300ms ease;

  &::before {
    transition: background 300ms ease, border 300ms ease, box-shadow 300ms ease;
  }

  &.selected {
    font-weight: bold;
  }

  &:hover {
    background-color: rgba(43, 135, 219, 0.1);

    &::before {
      border-color: var(--cb-color-black);
    }
  }

  &.label-active {
    &:hover::before {
      background-color: var(--cb-color-main-hover);
      border-color: white;
      box-shadow: 0 0 0 1px var(--cb-color-main-hover);
    }
  }

  & .arrow {
    color: var(--cb-color-main);
    transition: color 300ms ease;
  }

  &:hover .arrow {
    color: var(--cb-color-main-hover);
  }
}

.arrow {
  margin-left: 10px;
  display: flex;
  align-items: center;
  flex: 0 0 12px;
}

.text {
  flex: 1 1 100%;
}

.label {
  composes: cb-reset from '../../../lib/base-css/base.css';
  position: relative;
  cursor: pointer;
}

.label::before {
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -7px;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  cursor: pointer;
  content: '';
  border: 1px solid var(--cb-color-gray-1);
  border-radius: 50%;
  transition: 0.15s border, 0.05s border-width;
}

.label-active::before {
  background-color: var(--cb-color-main);
  border-width: 2px;
  border-color: var(--cb-color-white);
  box-shadow: 0 0 0 1px var(--cb-color-main);
}
