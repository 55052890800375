
.special_promo_block {
  position: relative;
  display: block;
  margin-top: 30px;
  padding: 18px 20px;
  height: 88px;
  background: url('./assets/pik320.jpg') no-repeat;
  background-size: cover;
  text-decoration: none;
  box-sizing: border-box;
  border-radius: 3px;

  @media (min-width: 640px) {
    margin: 30px 20px 10px;
    background: url('./assets/pik768.jpg') no-repeat;
    background-size: cover;
  }
}

.special_promo_block-icon {
  position: absolute;
  top: 50%;
  right: 24px;
  width: 60px;
  height: 20px;
  transform: translateY(-50%);
}

.title {
  margin-top: 0;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 1.38;
}

.description {
  display: block;
  margin: 0;
  font-size: 16px;
  color: white;
}
