.container {
  composes: cb-reset from '../../../lib/base-css/base.css';
  position: fixed;
  z-index: 20; /* над шапкой-микросервисом */
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  padding: 34px 20px;
  display: flex;
  align-items: center;
  background-color: rgba(48, 48, 48, 0.6);
  opacity: 1;
  transition: background-color 0.3s ease;
  backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;

  &.opened {
    /* нужна незначительная анимация, чтобы срабатывало onTransitionEnd */
    background-color: rgba(48, 48, 48, 0.7);
  }

  @media (max-width: 639px) {
    padding: 34px 10px;
  }
}

.inner {
  composes: cb-reset from '../../../lib/base-css/base.css';
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: 100%;
  background-color: transparent;
  transition: transform 0.3s ease;
  transform: scale(0);
  backface-visibility: hidden;
  align-items: stretch;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
}

.container.opened .inner {
  transform: scale(1);
}

.content {
  composes: cb-reset from '../../../lib/base-css/base.css';
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  background: var(--cb-color-white);
  border-radius: 2px 2px 0 0;
  -webkit-tap-highlight-color: transparent;
}

.circled {
  border-radius: 2px;
}

.buttons {
  composes: cb-reset from '../../../lib/base-css/base.css';
  display: flex;
  width: 100%;
  background-color: var(--cb-color-white);
  border-radius: 0 0 2px 2px;
  flex-shrink: 0;
}

.button {
  composes: cb-reset from '../../../lib/base-css/base.css';
  display: block;
  height: 55px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  color: var(--cb-color-main);
  border-top: solid 1px var(--cb-color-gray-3);
  border-right: solid 1px var(--cb-color-gray-3);
  flex-grow: 1;
  transition: color 300ms ease;

  &:last-child {
    border-right: none;
  }

  &:hover {
    color: var(--cb-color-main-hover);
  }
}
