.icon {
  margin-left: 8px;
  padding: 4px 8px;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100px;
}

.new {
  position: relative;
  top: -2px;
  margin-bottom: -3px;
  margin-left: 10px;
  display: inline-block;
  width: 28px;
  height: 14px;
  border-radius: 3px;

  & svg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
}
