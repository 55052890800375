.sorting-block {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: end;
}

.sorting-block.phone {
  justify-content: space-between;
}
