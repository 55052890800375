.buttons {
  /* Компенсируем правый margin у правого элемента и margin у field */
  margin-right: -10px;
  margin-bottom: -10px;

  & > div {
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;

    & div {
      padding-right: 15px;
      padding-left: 15px;
      transition: border 300ms ease, background 300ms ease;

      &:hover {
        border: solid 1px var(--cb-color-gray-1);
      }
    }
  }
}
