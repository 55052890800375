.license {
  margin-top: 8px;
  font-family: Lato, Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #7a7a7a;
}

.license-link {
  text-decoration: none;
  color: #2b87db;
}
