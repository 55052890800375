@custom-media --short-height-tablet (min-height: 600px) and (max-height: 767px) and (orientation: landscape);
@custom-media --not-short-height-tablet (orientation: portrait),
  (min-height: 768px) and (orientation: landscape),
  (max-height: 599px) and (orientation: landscape);

/* stylelint-disable declaration-no-important */

.onlyPhone {
  @media (width >= 640px) {
    display: none !important;
  }
}

.onlyTablet {
  @media (width < 640px) {
    display: none !important;
  }
}

.hidden {
  display: none !important;
}

.hideOnNarrowTablets {
  @media (--short-height-tablet) {
    display: none !important;
  }
}

.showOnNarrowTablets {
  @media (--not-short-height-tablet) {
    display: none !important;
  }
}
