.button {
  composes: cb-reset from '../../../../lib/base-css/base.css';
  position: relative;
  padding: 9px 40px 10px 15px;
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--cb-color-black);
  border: solid 1px var(--cb-color-gray);
  border-radius: 40px;

  &::after {
    position: absolute;
    top: 0;
    right: 11px;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    content: '';
    /* for correct svg load */
    background-image: url('./arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
}

.buttonText {
  display: block;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;

  &::first-letter {
    text-transform: capitalize;
  }
}

.group {
  &:not(:last-child) {
    border-bottom: 1px solid var(--cb-color-gray-3);
  }
}

.groupTitle {
  padding: 24px 20px 15px;
  display: block;
  font-size: 16px;
}

.popupContainer {
  max-width: 100%;
  max-height: 600px;
}

.popupHeader {
  height: 50px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.popupTitle {
  font-size: 14px;
  line-height: 1.29;
}

.closeButton {
  cursor: pointer;

  & span {
    position: absolute;
    top: 15px;
    color: rgba(0, 0, 0, 0.56);
    transition: color 300s ease;

    &:hover {
      & svg {
        color: var(--cb-color-black);
      }
    }
  }
}

.popupContent {
  padding-bottom: 20px;
  background-image: linear-gradient(to top, #e8e8e8 0, var(--cb-color-white) 5px);
  transform: translate3d(0, 0, 0);
  will-change: transform;
}

.checkboxContainer {
  display: flex;
  flex-wrap: wrap;
  min-width: 400px;

  & .labelCheckbox {
    padding: 18px 8px 18px 70px;
    display: block;
    font-size: 16px;
    line-height: 1.2;

    &::before {
      left: 40px;
    }

    &::after {
      left: 43px;
    }
  }

  & .labelCheckboxDisable {
    color: #c9c9c9;
    padding-right: 8px;
  }

  & .labelCheckboxNonDisable {
    color: #121212;
  }

  & .subCheckboxContainer {
    margin-left: 30px;
  }

  & .comingsoonWrapper {
    line-height: 55px;
  }

  & .comingsoon {
    padding: 2px 8px;
    background-color: #e8e9ec;
    font-size: 12px;
    line-height: 18px;
  }
}

.buttonError {
  border: 1px solid var(--cb-color-red);
}

.errorMessage {
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 0;
  left: 0;
  margin: 0 15px;
  padding: 2px 20px;
  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  color: var(--cb-color-white);
  background: var(--cb-color-red);
  border-radius: 3px;
}

.fixedContainer {
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 20px;
  padding: 0 5px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  transform: translate3d(0, 0, 0);
  will-change: transform;
}

.ieFixedContainer {
  bottom: 25px;
  padding: 0 10px;
}

.saveButton {
  width: 100%;
  text-align: center;
  cursor: pointer;
  border-radius: 42px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  transition: background 300ms ease, border 300ms ease;
  max-width: 300px;
  height: 42px;
  font-size: 16px;
  line-height: 1.25;

  &:hover {
    background-color: var(--cb-color-orange-hover);
    border-color: var(--cb-color-orange-hover);
  }
}

.customLabelCheckbox {
  width: 169px;
  font-size: 16px;
  cursor: pointer;
}

.customLabelCheckboxDisable {
  color: #c9c9c9;
  cursor: auto;
}

.itemContainer:first-child {
  margin-top: 34px;
}
.itemContainer {
  margin-top: 10px;
  margin-bottom: 10px;

  &.itemOneLine {
    line-height: 34px;
  }
}
.itemContainer:last-child {
  margin-top: 10px;
  margin-bottom: 24px;
}
