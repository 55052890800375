.counter {
  box-sizing: border-box;
  display: inline-table;
  font-weight: bold;
  text-align: center;
  transition: transform 100ms ease, background 300ms ease;
}

.content {
  display: table-cell;
  vertical-align: middle;
}

.sizeM {
  composes: counter;
  padding-right: 8px;
  padding-left: 8px;
  min-width: 24px;
  height: 24px;
  font-size: 14px;
  line-height: 14px;
  border-radius: 12px;
}

.sizeS {
  composes: counter;
  padding: 2px 6px 1px;
  min-width: 18px;
  height: 16px;
  font-size: 10px;
  line-height: 12px;
  border-radius: 100px;
}

.themeOrange {
  color: var(--cb-color-white);
  background-color: var(--cb-color-orange);
}

.themeGray {
  color: var(--cb-color-white);
  background-color: rgba(255, 255, 255, 0.3);
}

.themeDarkGray {
  color: var(--cb-color-white);
  background-color: rgba(18, 18, 18, 0.7);
}

.themeRed {
  color: var(--cb-color-white);
  background-color: #ff1f34;
}

.hidden {
  transform: scale(0);
}
