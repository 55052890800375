.header {
  composes: cb-reset from '../../../lib/base-css/base.css';
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  height: 50px;
  background-color: var(--cb-color-white);
  border-bottom: 1px solid rgba(18, 18, 18, 0.3);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-tap-highlight-color: transparent;
}

.heading {
  composes: cb-reset from '../../../lib/base-css/base.css';
  display: block;
  font-size: 16px;
  font-weight: var(--cb-font-semibold);
  line-height: 50px;
  text-align: center;
  color: var(--cb-color-black);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  box-sizing: border-box;
}

.fake-left {
  flex-basis: 48px;
  min-width: 20px;
  flex-grow: 0;
  flex-shrink: 10;
}

.header--region_select .fake-left {
  flex-basis: 33px;
}

.close-button {
  composes: cb-reset from '../../../lib/base-css/base.css';
  padding-right: 15px;
  width: 40px;
  height: 50px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.56);
  transition: color 300s ease;
  box-sizing: content-box;
  flex-basis: 20px;
  max-width: 20px;
  padding-left: 8px;
  flex-shrink: 0;
  flex-grow: 1;

  &:active {
    opacity: 0.6;
  }

  & svg {
    width: 10px;
    height: 10px;
  }

  &:hover {
    & svg {
      color: var(--cb-color-black);
    }
  }
}

.close-icon {
  margin: auto;
  width: 10px;
}

.back-button {
  composes: cb-reset from '../../../lib/base-css/base.css';
  cursor: pointer;
  color: rgba(0, 0, 0, 0.56);
  transition: color 300s ease;
  box-sizing: content-box;
  flex-basis: 20px;
  max-width: 20px;
  padding-left: 20px;
  flex-shrink: 0;
  flex-grow: 1;

  &:active {
    opacity: 0.6;
  }

  & svg {
    width: 6px;
    height: 10px;
    transform: rotate(-180deg);
    color: var(--cb-color-white);
  }

  &:hover {
    & svg {
      color: var(--cb-color-black);
    }
  }
}
