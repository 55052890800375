.root {
  composes: cb-reset from '../../../lib/base-css/base.css';
  top: 0;
  overflow: hidden;
  width: 100%;
  transition: top 0.3s;
  backface-visibility: hidden;
}

.sidebar {
  position: fixed;
  top: 0;
  height: 0;
  display: flex;
  overflow-y: hidden;
  flex-direction: row;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  backface-visibility: hidden;
  visibility: hidden;
}

.sidebarOpened {
  z-index: 101;
  height: 100%;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out;
}

.page {
  /* Чтобы страница тянулась по высоте в IE */
  display: flex;
  width: 100%;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
}

.fixedBlock {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;
  backface-visibility: hidden;
}

.sidebarOpenedWithBanner {
  top: 134px;
}

.overlay {
  background-color: #182930;
  opacity: 0.8;

  @media (width >= 640px) {
    width: calc(100% - 320px);
  }
}
