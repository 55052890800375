.preloader {
  display: inline-block;
  width: 32px;
  height: 32px;
  animation-name: rotate;
  animation-duration: 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}
