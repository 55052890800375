.reset {
  composes: cb-reset from '../../../../lib/base-css/base.css';
  margin: 0 auto;
  padding: 0;
  display: block;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  color: red;
  border: none;
  outline: none;
}
