.cb-reset,
.cb-reset::before,
.cb-reset::after {
  all: initial;
  box-sizing: border-box;
  color: #121212;
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%; /* чтобы шрифт не увеличивался при повороте экрана на iOS */
  -moz-osx-font-smoothing: grayscale;
}
