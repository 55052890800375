.container {
  composes: cb-reset from '../common/reset.css';
}

.container {
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border-style: solid;
  transition: 0.15s border, 0.15s background, 0.15s color;
  -webkit-tap-highlight-color: transparent;

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    cursor: default;

    &:active {
      transform: none;
    }
  }
}

.small {
  padding: 2px 15px 4px;
  border-width: 1px;
  border-radius: 14px;
}

.medium {
  composes: cb-medium_text from '../common/text_sizes.css';
  padding: 2px 22px 8px;
  border-width: 2px;
  border-radius: 21px;
}

.large {
  composes: cb-large_text from '../common/text_sizes.css';
  padding: 4px 29px 10px;
  border-width: 3px;
  border-radius: 28px;
}

.mobileMedium {
  composes: cb-medium_text from '../common/text_sizes.css';
  padding: 2px 22px 4px;
  font-size: 16px;
  border-width: 2px;
  border-radius: 21px;
}

.blue {
  color: var(--cb-color-main);
  border-color: rgba(43, 135, 219, 0.5);

  &:hover:not(.mobile) {
    color: var(--cb-color-main-hover);
    border-color: rgba(37, 107, 165, 0.5);
  }

  &:disabled {
    color: rgba(201, 201, 201, 0.9);
    border-color: rgba(201, 201, 201, 0.5);
  }
}

.blue_solid,
.toggle_active,
.toggle_active_uncheckable {
  color: var(--cb-color-white);
  background-color: var(--cb-color-main);
  border-color: var(--cb-color-main);
}

.blue_solid,
.toggle_active_uncheckable {
  &:hover:not(.mobile) {
    background-color: var(--cb-color-main-hover);
    border-color: var(--cb-color-main-hover);
  }
}

.blue_solid,
.toggle_active,
.toggle_active_uncheckable {
  &:disabled {
    background-color: var(--cb-color-gray-3);
    border-color: var(--cb-color-gray-3);
  }
}

.orange {
  color: var(--cb-color-orange);
  border-color: rgba(255, 157, 0, 0.5);

  &:hover:not(.mobile) {
    color: var(--cb-color-orange-hover);
    border-color: rgba(175, 104, 0, 0.5);
  }

  &:disabled {
    color: rgba(201, 201, 201, 0.9);
    border-color: rgba(201, 201, 201, 0.5);
  }
}

.orange_solid {
  color: var(--cb-color-white);
  background-color: var(--cb-color-orange);
  border-color: var(--cb-color-orange);

  &:hover:not(.mobile) {
    background-color: var(--cb-color-orange-hover);
    border-color: var(--cb-color-orange-hover);
  }

  &:disabled {
    background-color: var(--cb-color-gray-3);
    border-color: var(--cb-color-gray-3);
  }
}

.white {
  color: var(--cb-color-white);
  border-color: rgba(255, 255, 255, 0.5);

  &:hover:not(.mobile) {
    color: var(--cb-color-white);
    border-color: var(--cb-color-white);
  }

  &:disabled {
    color: rgba(201, 201, 201, 0.9);
    border-color: rgba(201, 201, 201, 0.5);
  }
}

.toggle {
  color: var(--cb-color-black);
  border-color: var(--cb-color-gray);

  &:hover:not(.mobile) {
    border-color: var(--cb-color-gray-hover);
  }

  &:disabled {
    color: rgba(201, 201, 201, 0.9);
    border-color: rgba(201, 201, 201, 0.5);
  }
}

.toggle,
.toggle_active,
.toggle_active_uncheckable {
  font-weight: normal;
}

.bluelight_solid {
  color: var(--cb-color-main);
  background-color: rgba(43, 135, 219, 0.1);
  background-clip: padding-box;
  border-color: rgba(43, 135, 219, 0.1);

  &:hover:not(.mobile) {
    color: var(--cb-color-main-hover);
    background-color: rgba(43, 135, 219, 0.15);
  }
}
