.container {
  /*
   * to prevent hiding during transition
   * (when opacity < 1)
   */
  position: relative;
  z-index: 17; /* over header */
  /* --- */

  opacity: 0;
  transition-duration: 300ms;
  transition-property: opacity, transform;
}

.visible {
  opacity: 1;
}
