.currency {
  display: flex;
  align-items: baseline;
}

.buttonsContainer {
  margin-right: 10px;
  width: 180px;
  height: 40px;
}
