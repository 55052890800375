.cb-small_text {
  font-size: 14px;
  line-height: 20px;
}

.cb-medium_text {
  font-size: 20px;
  line-height: 28px;
}

.cb-large_text {
  font-size: 26px;
  line-height: 36px;
}
