.topSection {
  padding-top: 10px;
}

.footer {
  margin: 20px auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  max-width: 400px;
  white-space: nowrap;
}

.public-date {
  background: white;
  border: 1px solid #e4e4e4;
  box-shadow: 0 1px 0 0 #e4e4e4;
}

.filter-only-block {
  padding: 15px 15px 25px;
  background: white;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
}

.photo-filter {
  margin-bottom: 15px;
}
