.selectedRegion {
  composes: cb-reset from '../../../../lib/base-css/base.css';
  width: 100%;
  font-size: 16px;
  font-weight: inherit;
  white-space: nowrap;
  cursor: pointer;
  color: var(--cb-color-main);
  transition: color 300ms ease;

  &:hover {
    color: var(--cb-color-main-hover);
  }
}

.preloader {
  margin-top: 2px;
}
