.error {
  display: flex;
  background-color: var(--cb-color-red);
  opacity: 0.95;
}

.message {
  padding: 15px 20px;
  font-size: 16px;
  font-weight: bold;
  white-space: pre-wrap;
  color: var(--cb-color-white);
  flex-grow: 1;
}

.withRefresh .message {
  /* reset padding because we have refresh button on right side */
  padding-right: 0;
}

.refreshArea {
  display: flex;
  -webkit-tap-highlight-color: transparent;
}

.refresh {
  margin: auto 20px auto 12px;
  height: 19px;
}
