.block {
  position: relative;
  margin: 0;
  display: block;
  background-color: var(--cb-color-white);
  border: solid 1px var(--cb-color-gray-3);
  border-radius: 3px;
  box-shadow: 0 1px 0 0 var(--cb-color-gray-3);
}

.block:empty {
  border: none;
}

.section {
  margin: 0;
  padding: 15px;
  display: block;
  line-height: 1.2;
  border-top: 1px solid var(--cb-color-gray-5);
}

.section:not(:empty) {
  border-top: none;
}

.section:not(:empty) ~ .section:not(:empty) {
  border-top: 1px solid var(--cb-color-gray-5);
}

.title {
  composes: cb-reset from '../../../lib/base-css/base.css';
  margin-bottom: 9px;
  padding: 15px 15px 0;
  display: block;
  font-size: 16px;
  font-weight: var(--cb-font-semibold);
  color: var(--cb-color-black);
}

.noPadding {
  padding: 0;
}
