.sort {
  position: relative;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.sort-select {
  position: absolute;
  -webkit-appearance: none;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  color: transparent;
  background-color: transparent;
  border: 0;
}

.sort-select::-ms-expand {
  display: none;
}

.fake-select {
  display: flex;
}

.fake-select .title {
  font-size: 14px !important;
  font-weight: bold;
  color: #1983e1;
}

.fake-select .arrow {
  margin: 0 0 0 5px;
  display: block;
}
