@keyframes mobile_login_motivation_popup_button_animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-motivation-popup {
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: 0;
  display: none;
  visibility: hidden;
  min-width: 312px;
  text-align: left;
  opacity: 0;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
  transition: opacity 0.2s ease, visibility 0s 0.2s;
  transform: translateY(100%);
}

.login-motivation-popup_container {
  position: relative;
  z-index: 1;
  padding: 12px 16px;
  background: white url('./login_motivation_popup.svg') no-repeat bottom right 8px;
}

.login-motivation-popup::after {
  position: absolute;
  top: -5px;
  right: 16px;
  display: block;
  content: '';
  border-top: 16px solid white;
  border-left: 16px solid transparent;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
  transform: rotate(315deg);
}

.login-motivation-popup.login-motivation-popup--visible {
  visibility: visible;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease, visibility 0s;
}

.login-motivation-popup_title {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
}

.login-motivation-popup_text {
  margin: 0 0 15px;
  font-size: 14px;
  line-height: 18px;
}

.login-motivation-popup_button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.login-motivation-popup_button-container button {
  white-space: nowrap;
}

.login-motivation-popup_button-container button + button {
  margin-left: 8px;
}

.button-enter {
  position: relative;
  padding: 4px 68px 5px;
  &::after {
    content: '';
    position: absolute;
    opacity: 0;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -8px;
    width: 16px;
    height: 16px;
    background: url('./preloader-white.svg') no-repeat;
    transition: opacity 0.2s ease;
    animation: mobile_login_motivation_popup_button_animation 1000ms linear infinite;
    animation-play-state: paused;
  }
}

.button-text {
  opacity: 1;
  transition: opacity 0.2s ease 0.2s;
}

.button-enter.button-enter--loader {
  & .button-text {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &::after {
    opacity: 1;
    transition: opacity 0.2s ease 0.2s;
    animation-play-state: running;
  }
}
