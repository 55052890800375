@import '@cian/ui-kit/colors/variables.css';

.container {
  position: relative;
  z-index: 10;
  background: #e6f0ff;
}

.wrapper {
  margin: 0 auto;
  padding: 16px 20px 12px;
  display: flex;
  align-items: flex-start;
  min-width: 320px;
  max-width: 1376px;
}

.logotype {
  flex: 0 0 auto;
  margin-right: 12px;
  width: 28px;
  height: 28px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}

.text {
  margin-top: -4px;
}

.nowrap {
  white-space: nowrap;
}

.emoji {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-image: url('./assets/emoji_wink.png');
  background-position: center;
  background-size: cover;
}

.close {
  margin-top: -8px;
  margin-right: -10px;
  padding: 8px;
  cursor: pointer;
  background: none;
  border: none;
}

@media (min-width: 641px) {
  .wrapper {
    padding: 10px 20px;
    align-items: center;
  }

  .logotype {
    background-position: center;
  }

  .text {
    margin-top: 0;
  }

  .close {
    margin-top: 0;
    margin-right: -8px;
    margin-left: auto;
  }
}
