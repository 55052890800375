.rangeGroup {
  composes: cb-reset from '../../../../lib/base-css/base.css';
  display: flex;
  flex-basis: 0;
}

/* tricky styles for tooltipped inputs */

/*
  .group
    .input
    .input
*/

.container {
  display: block;
  flex-grow: 1;

  &:first-child {
    & .input {
      border-radius: 3px 0 0 3px;
    }
  }

  &:last-child {
    margin-left: -1px;

    & .input {
      border-radius: 0 3px 3px 0;
    }
  }
}

/*
  .group
    .input
    .tooltip
      .input

  .group
    .tooltip
      .input
    .input
*/

.tooltip {
  z-index: 2;
  display: block;
  flex-grow: 1;

  &:first-child {
    & .input {
      border-radius: 3px 0 0 3px;
    }
  }

  &:last-child {
    margin-left: -1px;

    & .input {
      border-radius: 0 3px 3px 0;
    }
  }
}

/*
  .group
    .tooltip
      .input
      .input
*/

.wide {
  & .container {
    display: block;
    flex-grow: 1;

    &:first-child {
      & .input {
        border-radius: 3px 0 0 3px;
      }
    }

    &:last-child {
      margin-left: -1px;

      & .input {
        border-radius: 0 3px 3px 0;
      }
    }
  }
}

.focused,
.error {
  z-index: 1;
}

.error {
  & .input {
    border: 1px solid var(--cb-color-red);
  }
}

.input {
  padding: 9px 9px 10px;
  font-size: 16px;
  line-height: 1.2;
  flex-grow: 1;
}
