.container {
  position: fixed;
  z-index: 120;
  top: 0;
  left: 0;
  padding: 10px;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-family: Lato, sans-serif;
  font-size: 14px;
  background: rgba(24, 41, 48, 0.8);
  align-items: center;
  justify-content: center;
}

.container * {
  box-sizing: border-box;
}

.container label + div > span > input {
  font-size: 100%;
}

.wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  max-width: 400px;
  max-height: 100%;
  background-color: white;
  border-radius: 4px;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
  display: block;
  box-sizing: initial;
  width: 14px;
  height: 14px;
  cursor: pointer;
  background: none;
  border: 0;
}

.close::before,
.close::after {
  position: absolute;
  top: 18px;
  display: block;
  width: 15px;
  height: 2px;
  content: '';
  background: #737A8E;
}

.close::before {
  transform: rotate(45deg);
}

.close::after {
  transform: rotate(-45deg);
}

.message {
  padding: 12px 36px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.27;
  text-align: center;
  background-color: rgba(255, 157, 0, 0.1);
}
