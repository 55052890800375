.button {
  margin-left: 8px;
  padding: 6px 21px 0 16px;
  height: 32px;
  font-size: 12px;
  font-weight: var(--cb-font-semibold);
  white-space: nowrap;
  cursor: pointer;
  background: var(--cb-color-white);
  border: 0;
  border-radius: 100px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
}

.button::after {
  width: 13px;
  height: 34px;
  top: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.button.leftMargin {
  margin-left: 8px;
}

.button.opened .arrow {
  transform: rotate(270deg);
}

.button.disabled {
  color: #999;
}

.arrow {
  margin: 2px 0 0 10px;
  height: 18px;
  color: var(--cb-color-main);
  transition: 0.15s transform;
  transform: rotate(90deg);
}

.content {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.counter {
  margin: 0 0 0 5px;
}

.button.disabled .counter {
  background-color: rgba(18, 18, 18, 0.4);
}

.button.disabled .arrow {
  color: rgba(18, 18, 18, 0.4);
}
