.logo {
  composes: cb-reset from '../../../lib/base-css/base.css';
  margin-top: -5px;
}

.inscription {
  margin-left: 6px;
}

.inscription svg {
  vertical-align: middle;
}
