.container {
  margin-right: 40px;
}

.button {
  composes: cb-reset from '../../../../lib/base-css/base.css';
  padding: 0 0 0 25px;
  display: block;
  font-family: Lato, Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  color: #2b87db;
  background: transparent url('./search.svg') left center no-repeat;
  border: none;
  outline: none;

  @media (width < 640px) {
    padding: 0;
    background: transparent;
  }
}
