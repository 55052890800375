@keyframes save_filter_popup_animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  position: fixed;
  padding: 34px 10px;
}

.content {
  position: relative;
  overflow-y: hidden;
  height: initial;
  padding: 31px 14px 20px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;

}

.cotnent--auth {
  padding-bottom: 20px;
}

.inner {
  margin: 0 auto;
  max-width: 350px;
  height: auto;

  @media (width < 640px) {
    max-width: initial;
  }
}

.close-icon {
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
  color: #929294;
  transition: color 0.3s ease;

  &:hover {
    color: #a7a7a7;
  }
}

.input {
  padding: 9px 10px;
}

.field + .field {
  margin-top: 12px;

  @media (width < 640px) {
    margin-top: 16px;
  }
}

.field_notification {
  position: relative;
}

.field_loader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
}

.button {
  position: relative;
  margin: 20px -1px 0;
  padding: 9px 0 11px;
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  border-radius: 22px;
  transition: opacity 0.3s ease 0.3s;

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    content: '';
    background: url('./preloader-white.svg') no-repeat;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}

.button-text {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.button--loader {
  & .button-text {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &::after {
    opacity: 1;
    transition: opacity 0.3s ease 0.3s;
    animation: save_filter_popup_animation 1000ms linear infinite;
  }
}

.title {
  margin: 0;
  font-family: Lato, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  text-align: center;
  color: #121212;
}

.header {
  margin-bottom: 8px;
  font-family: Lato;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.27;
  text-align: center;
  color: #121212;
}

.sub-header {
  margin-bottom: 16px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  text-align: center;
  color: #121212;
}

.checkbox {
  margin-top: 12px;
  display: block;
}

.license {
  margin-top: 8px;
  font-family: Lato, Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #7a7a7a;
}

.license-link {
  text-decoration: none;
  color: #2b87db;
}

.label-container {
  display: flex;
  flex-flow: row nowrap;
}

.label {
  margin: auto 0;
}

.icon-container {
  position: relative;
  margin: auto 0 auto 4px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('./tooltip.svg') no-repeat;
}

.tooltip {
  margin-left: -20px;
}

.success-text {
  margin: 0 30px 16px;
  line-height: 1.38;
}

.success-email {
  margin-bottom: 12px;
  padding-left: 20px;
  display: inline-block;
  font-weight: bold;
  background: url('./subscript_success.svg') left bottom 3px no-repeat;
}

.form {
  margin-top: 20px;
}

.success-container {
  margin-top: 7px;
  font-size: 16px;
  text-align: center;
  color: #121212;
}

.subscription-period {
  box-sizing: border-box;
  width: 100%;
}

.subscription-period button::after {
  background-image: url('./chevron.svg');
}

.subscription-period button {
  padding-left: 10px;
  width: 100%;
  height: 40px;
  color: #121212;
  background: white;
  border-color: #c9c9c9;
  border-radius: 4px;
  transform: scale(1);
}

.subscription-period button:active {
  transform: scale(1);
}

.subscription-period button::after {
  top: 17px;
}

.popup-period {
  width: 100%;
  box-sizing: border-box;

  &::before,
  &::after {
    left: auto;
    right: 13px;
  }

  &::before {
    right: 12px;
  }
}

@media (max-height: 480px) {
  .subscription-period button::after {
    transition: none;
  }

  .popup-period::after,
  .popup-period::before {
    display: none;
  }

  .popup-period {
    top: 0;
    left: 0;
    margin-top: 0 !important;
  }
}

.emoji {
  position: relative;
  top: 2px;
  font-size: 20px;
}
