.checkboxContainer {
  margin-top: 20px;
  display: block;

  &.desktop {
    margin-top: 10px;
  }

  &:first-of-type {
    margin-top: 0;
  }
}

