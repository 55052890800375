.root {
  display: inline-flex;
  align-items: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #0468ff;
  cursor: pointer;
  flex-wrap: nowrap;
}

.icon {
  margin-left: 6px;
}
